import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Bounds } from 'google-map-react';
import { HYDRATE } from 'next-redux-wrapper';

import { DEFAULT_MAP_ZOOM_CITY } from '@/utils/constants';

import type { AppState } from '../app';

export interface IMapState {
  zoom: number;
  distance: number;
  bounds?: Bounds;
  coordinates?: ICoordinates;
}

const initialState: IMapState = {
  distance: 0,
  zoom: DEFAULT_MAP_ZOOM_CITY,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    updateMapZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    updateMapDistance: (state, action: PayloadAction<number>) => {
      state.distance = action.payload;
    },
    updateMapCoordinates: (state, { payload }: PayloadAction<ICoordinates>) => {
      state.coordinates = {
        lat: parseFloat(payload.lat.toPrecision(6)),
        lng: parseFloat(payload.lng.toPrecision(6)),
      };
    },
    updateMapBounds: (state, action: PayloadAction<Bounds>) => {
      state.bounds = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(HYDRATE, (state, { payload }: any) => {
      if (
        !payload ||
        !('map' in payload) ||
        (state.zoom && state.coordinates)
      ) {
        return state;
      } else {
        return payload.map;
      }
    }),
});

export const {
  actions: {
    updateMapZoom,
    updateMapBounds,
    updateMapDistance,
    updateMapCoordinates,
  },
  reducer: mapReducer,
} = mapSlice;

// Selectors

export const selectMapState = (state: AppState) => state.map;

export const selectMapZoom = createSelector(selectMapState, (map) => map.zoom);

export const selectMapDistance = createSelector(
  selectMapState,
  (map) => map.distance,
);

export const selectMapCoordinates = createSelector(
  selectMapState,
  (map) => map.coordinates,
);

export const selectMapBounds = createSelector(
  selectMapState,
  (map) => map.bounds,
);
