import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { groupBy } from 'lodash';
import type { Bounds } from 'google-map-react';

import {
  failedWithError,
  getResourceData,
  hydrate,
  IResource,
  isResourceIdle,
  isResourceLoading,
  loaded,
  loading,
  ResourceStatus,
} from '@/utils/resource';
import type { ClusterPoint } from '@/types/map';
import { transformToRecord } from '@/utils/transform-to-record';

import { selectProductIdQueryParam, selectShopIdQueryParam } from '../services';
import type { AppState, AppThunkExtra } from '../app';

type ShopId = IShopDTO['id'];

export type IShopsState = IResource<Partial<Record<ShopId, IShopDTO>>>;

// const DEFAULT_BOUNDS: Bounds = {
//   ne: { lat: 0, lng: 0 },
//   nw: { lat: 0, lng: 0 },
//   se: { lat: 0, lng: 0 },
//   sw: { lat: 0, lng: 0 },
// };

const initialState: IShopsState = {
  status: ResourceStatus.idle,
};

export const fetchShopsAndProductsByLocation = createAsyncThunk<
  {
    activeShopId?: string;
    activeProductId?: string;
    shops: Array<IShopDTO>;
    products: Array<IProductDTO>;
    clusters: Array<ClusterPoint>;
  },
  { zoom: number; bounds: Bounds },
  AppThunkExtra
>(
  'shops/products/getByLocation',
  async ({ zoom, bounds }, { getState, extra: { api } }) => {
    const activeShopId = selectShopIdQueryParam(getState());
    const activeProductId = selectProductIdQueryParam(getState());

    const { clusters, shops, products } =
      await api.shops.getShopsAndProductsByLocation({
        bounds: [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat],
        zoom,
      });

    return {
      shops,
      clusters,
      products,
      activeShopId,
      activeProductId,
    };
  },
);

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    preloadActiveShop: (
      state,
      { payload: activeShop }: PayloadAction<IShopDTO>,
    ) =>
      hydrate(state, {
        ...getResourceData(state),
        [activeShop.id]: activeShop,
      }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(HYDRATE, (state, { payload }: any) => {
        if (!payload || !('shops' in payload) || !isResourceIdle(state)) {
          return state;
        }

        return hydrate(state, {
          ...getResourceData(state),
          ...getResourceData(payload.shops),
        });
      })

      .addCase(fetchShopsAndProductsByLocation.pending, loading)
      .addCase(fetchShopsAndProductsByLocation.rejected, failedWithError)
      .addCase(
        fetchShopsAndProductsByLocation.fulfilled,
        (state, { payload: { activeShopId, shops } }) => {
          const activeShopData = activeShopId
            ? { [activeShopId]: getResourceData(state)?.[activeShopId] }
            : {};

          return loaded(state, {
            ...activeShopData,
            ...transformToRecord(shops),
          });
        },
      ),
});

export const {
  actions: { preloadActiveShop },
  reducer: shopsReducer,
} = shopsSlice;

// Selectors

export const selectShopsState = (state: AppState): IShopsState => state.shops;

export const selectAreShopsLoading = createSelector(
  selectShopsState,
  isResourceLoading,
);

export const selectShopRecords = createSelector(selectShopsState, (resource) =>
  getResourceData(resource),
);

export const selectShopsList = createSelector(
  selectShopRecords,
  (shopRecords): Array<IShopDTO> => {
    if (!shopRecords) return [];

    return Object.values(shopRecords) as Array<IShopDTO>;
  },
);

export const selectGroupedByLocationShopsList = createSelector(
  selectShopsList,
  (shops) =>
    Object.values(
      groupBy(shops, ({ coordinates: { lat, lng } }) =>
        [parseFloat(lat.toFixed(6)), parseFloat(lng.toFixed(6))].join('-'),
      ),
    ).map((group) => (group.length === 1 ? group[0] : group)),
);

export const selectIsShopLoadedById = createSelector(
  selectShopRecords,
  (shops) => (id: string) => shops && id in shops,
);
