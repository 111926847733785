import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAppDispatch } from '@/store/app';
import { setQueryParams } from '@/store/services';

export const useStoreQueryParams = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setQueryParams(router.query));
  }, [dispatch, router.query]);
};
