import { useSelector } from 'react-redux';

import type {
  IMapState,
  IProductsState,
  IShopsState,
  IUserShopState,
  IUserState,
  ICategoriesState,
  IUserProductsState,
  INotificationsState,
  IDraftProductState,
  IClustersState,
} from '../domains';

export type AppState = {
  map: IMapState;
  user: IUserState;
  shops: IShopsState;
  products: IProductsState;
  userShop: IUserShopState;
  clusters: IClustersState;
  categories: ICategoriesState;
  queryParams: IAppQueryParams;
  draftProduct: IDraftProductState;
  userProducts: IUserProductsState;
  notifications: INotificationsState;
};

export const useAppSelector = <R>(selector: (state: AppState) => R) =>
  useSelector(selector);
