import { API_URL } from '@/common/client/constants';

import { BaseApiProvider } from './Base.api';

export class CategoriesApiProvider extends BaseApiProvider {
  static create() {
    return new CategoriesApiProvider({ baseURL: `${API_URL}/categories` });
  }

  getCategories = () => this.get<Array<ICategoryDTO>>('');
}
