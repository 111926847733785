import { createSelector } from '@reduxjs/toolkit';

import { selectShopIdQueryParam } from '../services';
import { selectUserShop } from './user-shop';
import { selectUserProductsList } from './user-products';
import { selectProductsList } from './products';
import { selectShopRecords } from './shops';

export const selectIsActiveUserShop = createSelector(
  selectUserShop,
  selectShopIdQueryParam,
  (userShop, activeShopId) =>
    Boolean(userShop && activeShopId && userShop?.id === activeShopId),
);

export const selectActiveShop = createSelector(
  selectUserShop,
  selectIsActiveUserShop,
  selectShopIdQueryParam,
  selectShopRecords,
  (userShop, isActiveUserShop, activeShopId, shopRecords) => {
    if (isActiveUserShop) return userShop;
    return activeShopId ? shopRecords?.[activeShopId] : undefined;
  },
);

export const selectActiveShopCoordinates = createSelector(
  selectActiveShop,
  (activeShop) => {
    if (!activeShop) return;
    return activeShop.coordinates;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: (c1?: ICoordinates, c2?: ICoordinates) => {
        if (!c1 && !c2) return true;
        return c1?.lng === c2?.lng && c1?.lat === c2?.lat;
      },
    },
  },
);

export const selectActiveShopProductsList = createSelector(
  selectShopIdQueryParam,
  selectProductsList,
  selectIsActiveUserShop,
  selectUserProductsList,
  (activeShopId, products, isActiveUserShop, userProducts) => {
    if (isActiveUserShop) return userProducts;

    return products?.filter(({ shopId }) => shopId === activeShopId);
  },
);
