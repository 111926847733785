import { API_URL } from '@/common/client/constants';

import { BaseApiProvider } from './Base.api';

export class AuthApiProvider extends BaseApiProvider {
  static create() {
    return new AuthApiProvider({
      baseURL: `${API_URL}/auth`,
      withCredentials: true,
    });
  }

  signInWithFirebase = (token: string) =>
    this.post<UserSignInRequestV2, UserSignInResponseV2>('', { token });

  signOut = () => this.delete<UserSignOutResponseV2>('');
}
