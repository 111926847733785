import { API_URL } from '@/common/client/constants';

import { BaseApiProvider } from './Base.api';

export class ImagesApiProvider extends BaseApiProvider {
  static create() {
    return new ImagesApiProvider({
      baseURL: `${API_URL}/images`,
      withCredentials: true,
    });
  }

  uploadShopImages = async (images: Array<File>) => {
    const responses = await Promise.all(
      images.map((image) => {
        const formData = new FormData();

        formData.append('image', image);

        return this.post<
          IProductImageUploadRequest,
          IProductImageUploadResponse
        >('/upload/shops', formData as any);
      }),
    );

    return responses.map(({ url }) => url);
  };

  uploadProductImages = async (images: Array<File>) => {
    const responses = await Promise.all(
      images.map((image) => {
        const formData = new FormData();

        formData.append('image', image);

        return this.post<
          IProductImageUploadRequest,
          IProductImageUploadResponse
        >('/upload/products', formData as any);
      }),
    );

    return responses.map(({ url }) => url);
  };

  uploadRawShopImages = async (images: Array<ICreateImageDTO>) => {
    const urlImages = images.filter(({ file }) => !file).map(({ src }) => src);
    const rawImages = images
      .filter(({ file }) => file)
      .map(({ file }) => file) as Array<File>;

    const uploadedImages = await this.uploadShopImages(rawImages);

    return [...urlImages, ...uploadedImages];
  };

  uploadRawProductImages = async (images: Array<ICreateImageDTO>) => {
    const urlImages = images.filter(({ file }) => !file).map(({ src }) => src);
    const rawImages = images
      .filter(({ file }) => file)
      .map(({ file }) => file) as Array<File>;

    const uploadedImages = await this.uploadProductImages(rawImages);

    return [...urlImages, ...uploadedImages];
  };
}
