import type { ParsedUrlQuery } from 'querystring';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from '../../app';
import { validateAppQueryParams } from './validate';

const initialState: IAppQueryParams = {};

const queryParamsSlice = createSlice({
  name: 'queryParams',
  initialState,
  reducers: {
    setQueryParams: (_, action: PayloadAction<ParsedUrlQuery>) =>
      validateAppQueryParams(action.payload),
  },
});

export const {
  reducer: queryParamsReducer,
  actions: { setQueryParams },
} = queryParamsSlice;

// Selectors

export const selectQueryParamsState = (state: AppState) => state.queryParams;

const createSelectQueryParamSelector = <P extends keyof IAppQueryParams>(
  param: P,
) =>
  createSelector(
    selectQueryParamsState,
    (state): IAppQueryParams[P] => state[param],
  );

export const selectShopIdQueryParam = createSelectQueryParamSelector('shopId');
export const selectShopViewTabQueryParam =
  createSelectQueryParamSelector('shopTab');

export const selectProductIdQueryParam =
  createSelectQueryParamSelector('productId');
export const selectProductViewTabQueryParam =
  createSelectQueryParamSelector('productTab');

export const selectCoordinatesQueryParam = createSelector(
  createSelectQueryParamSelector('lat'),
  createSelectQueryParamSelector('lng'),
  (lat, lng): ICoordinates | undefined =>
    lat !== undefined && lng !== undefined ? { lat, lng } : undefined,
);

export const selectLocationDistanceQueryParam =
  createSelectQueryParamSelector('r');

export const selectLocationQueryParam = createSelector(
  selectCoordinatesQueryParam,
  selectLocationDistanceQueryParam,
  (coordinates = { lat: 0, lng: 0 }, r = 0): ILocationQueryParams => ({
    ...coordinates,
    r,
  }),
);

export const selectCategoryIdQueryParam = createSelector(
  createSelectQueryParamSelector('categoryId'),
  (categoryIdQueryParam) => categoryIdQueryParam ?? [],
);
