import { onlyDigits } from './string';

export const DEFAULT_PHONE_COUNTRY_CODE = '380';
export const DEFAULT_PHONE_NUMBER_LENGTH = 12;

export const normalizePhoneNumber = (
  phoneNumber = '',
  countryCode = DEFAULT_PHONE_COUNTRY_CODE,
) => {
  const phone = onlyDigits(phoneNumber);
  return !phone.startsWith(countryCode) ? countryCode + phone : phone;
};

export const removePhoneNumberCountryCode = (
  phoneNumber = '',
  countryCode = DEFAULT_PHONE_COUNTRY_CODE,
) => {
  return phoneNumber.replace(countryCode, '');
};

export const isPhoneNumberValid = (
  phoneNumber = '',
  countryCode = DEFAULT_PHONE_COUNTRY_CODE,
  phoneNumberLength = DEFAULT_PHONE_NUMBER_LENGTH,
) => {
  const phone = normalizePhoneNumber(phoneNumber, countryCode);
  return phone.length === phoneNumberLength;
};

export const sanitizePhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace('+', '');
};
