import { HYDRATE } from 'next-redux-wrapper';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  cleared,
  IResource,
  isResourceIdle,
  ResourceStatus,
} from '@/utils/resource';
import { ProductStatus } from '@/utils/constants';
import { isPresent } from '@/utils/is-present';

import type { AppState } from '../app';
import {
  createProduct,
  updateProduct,
  CreateProductData,
} from './user-products';
import { createShop } from './user-shop';

export interface IDraftProductData
  extends Omit<IProductDTO, 'id' | 'shopId' | 'imageUrls'> {
  imageUrls: Array<ICreateImageDTO>;
}

export type IDraftProductState = IResource<IDraftProductData>;

export const isDraftProductData = (data: any): data is IDraftProductData =>
  isPresent(data) &&
  'imageUrls' in data &&
  Array.isArray(data.imageUrls) &&
  data.imageUrls.every(
    (imageUrl: string | ICreateImageDTO) => typeof imageUrl !== 'string',
  );

const defaultState: IDraftProductData = {
  name: '',
  price: 1,
  unit: 'KG',
  updatedAt: 0,
  imageUrls: [],
  categoryId: '',
  description: '',
  status: ProductStatus.Active,
};

const initialState: IDraftProductState = {
  status: ResourceStatus.ready,
};

const draftProductSlice = createSlice({
  name: 'draftProduct',
  initialState,
  reducers: {
    updateDraftProduct: (
      state,
      { payload: { ...payload } }: PayloadAction<Partial<CreateProductData>>,
    ) => {
      state.data = {
        ...(state.data ?? defaultState),
        ...payload,
      };
    },
    removeDraftProduct: (state) => {
      state.data = undefined;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(HYDRATE, (state, { payload }: any) =>
        payload && 'draftProduct' in payload && isResourceIdle(state)
          ? payload.draftProduct
          : state,
      )
      .addCase(createProduct.fulfilled, cleared)
      .addCase(updateProduct.fulfilled, cleared)
      .addCase(createShop.fulfilled, cleared),
});

export const {
  actions: { removeDraftProduct, updateDraftProduct },
  reducer: draftProductReducer,
} = draftProductSlice;

// Selectors

export const selectDraftProductState = (state: AppState) => state.draftProduct;

export const selectDraftProductData = createSelector(
  selectDraftProductState,
  (draftProduct) => draftProduct.data,
);

export const selectDraftProductDataOrDefaults = createSelector(
  selectDraftProductState,
  (draftProduct) => draftProduct.data ?? defaultState,
);
