export const ACCESS_TOKEN_HEADER = 'x-ebazar-access-token';
export const REFRESH_TOKEN_HEADER = 'x-ebazar-refresh-token';

export enum ProductStatus {
  Active = 'active',
  Deactivated = 'deactivated',
}

export enum ShopStatus {
  Active = 'active',
  Inactive = 'inactive',
  Deactivated = 'deactivated',
}

export const UNITS: Array<Unit> = ['KG', 'L', 'PCS'];

export const SHOP_STATUSES: Array<ShopStatus> = [
  ShopStatus.Active,
  ShopStatus.Inactive,
  ShopStatus.Deactivated,
];
export const PRODUCT_STATUSES: Array<ProductStatus> = [
  ProductStatus.Active,
  ProductStatus.Deactivated,
];

export const LANGUAGE_CODE = 'uk';

export enum CategoryName {
  Vegetables = 'Vegetables',
  Melons = 'Melons',
  Greens_Spices = 'Greens_Spices',
  Fruits = 'Fruits',
  Berries = 'Berries',
  Nuts = 'Nuts',
  Legumes = 'Legumes',
  Cereals_Grains = 'Cereals_Grains',
  Oil = 'Oil',
  AnimalMilk = 'AnimalMilk',
  VegetableMilk = 'VegetableMilk',
  Dairy = 'Dairy',
  Cheese = 'Cheese',
  Eggs = 'Eggs',
  Meat_Lard = 'Meat_Lard',
  MeatDelicacies = 'MeatDelicacies',
  BeeProducts = 'BeeProducts',
  Mushrooms = 'Mushrooms',
  FreshFish = 'FreshFish',
  FishDelicacies_Seafood = 'FishDelicacies_Seafood',
  Grocery = 'Grocery',
  Bread_Baking = 'Bread_Baking',
  Sweets_Snacks = 'Sweets_Snacks',
  Drinks = 'Drinks',
  Conservation = 'Conservation',
  FrozenFood = 'FrozenFood',
  Animals_Livestock = 'Animals_Livestock',
  Plants_Flowers = 'Plants_Flowers',
  Seedlings = 'Seedlings',
  PoultryMeat = 'PoultryMeat',
}

export const DEFAULT_MAP_ZOOM_CITY = 11;
export const DEFAULT_MAP_ZOOM_COUNTRY = 6;
export const MIN_MAP_ZOOM = 4;
export const MAX_MAP_ZOOM = 15;

export const KYIV_COORDINATES: ICoordinates = {
  lat: 50.450001,
  lng: 30.523333,
};

export const UKRAINE_COORDINATES: ICoordinates = {
  lat: 48.3794,
  lng: 31.1656,
};

export const GOOGLE_API_LIBRARIES = ['geometry' as const, 'places' as const];
