import { useCallback, useState } from 'react';

export const useOpenState = (initialOpen = false) => {
  const [open, setOpen] = useState(initialOpen);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  const onToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), []);

  return { open, onOpen, onClose, onToggle };
};
