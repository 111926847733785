import {
  createSelector,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import {
  failed,
  cleared,
  ResourceStatus,
  IResource,
  getResourceData,
  readyWithPayload,
  isResourceReadyWithData,
  isResourceIdle,
} from '@/utils/resource';

import type { AppState } from '../app';

export type IUserState = IResource<IUserDTO>;

const initialState: IUserState = {
  status: ResourceStatus.idle,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    preload: (state, action: PayloadAction<IUserDTO>) =>
      readyWithPayload(state, action),

    signIn: (state, action: PayloadAction<IUserDTO>) =>
      readyWithPayload(state, action),
    signOut: cleared,

    authFailed: (state, action: PayloadAction<SerializedError>) =>
      failed(state, action.payload),
  },
  extraReducers: (builder) =>
    builder.addCase(HYDRATE, (state, { payload }: any) =>
      payload && 'user' in payload && isResourceIdle(state)
        ? payload.user
        : state,
    ),
});

export const {
  actions: { preload: preloadUser, signIn, signOut, authFailed },
  reducer: userReducer,
} = userSlice;

// Selectors

export const selectUserState = (state: AppState) => state.user;

export const selectUserResourceData = createSelector(
  selectUserState,
  (resource) => getResourceData(resource),
);

export const selectIsUserSignedIn = createSelector(
  selectUserState,
  isResourceReadyWithData,
);

export const selectUserPhoneNumber = createSelector(
  selectUserResourceData,
  (user) => user?.phoneNumber,
);
