import { useEffect } from 'react';
import getConfig from 'next/config';
import * as FullStory from '@fullstory/browser';

import { isBrowser } from '@/utils/is-browser';

const {
  publicRuntimeConfig: { IS_DEVELOPMENT, FULL_STORY_ORG_ID },
} = getConfig();

export const useFullStory = () => {
  useEffect(() => {
    if (isBrowser) {
      FullStory.init({ orgId: FULL_STORY_ORG_ID, debug: IS_DEVELOPMENT });
    }
  }, []);
};
