import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { isBrowser } from '@/utils/is-browser';

export interface IAppHeightContext {
  height: string;
}

const AppHeightContext = createContext<IAppHeightContext>({ height: '100vh' });

export const AppHeightProvider: React.FC = ({ children }) => {
  const [height, setHeight] = useState('100vh');
  const updateHeight = useCallback(
    () => setHeight(isBrowser ? `${window.innerHeight}px` : '100vh'),
    [],
  );

  useEffect(() => {
    if (!isBrowser) return;
    updateHeight();

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [updateHeight]);

  return (
    <AppHeightContext.Provider value={{ height }}>
      {children}
    </AppHeightContext.Provider>
  );
};

export const useAppHeight = () => useContext(AppHeightContext).height;
