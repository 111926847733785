import type {
  ClusterPoint,
  ShopClusterPoint,
  SingleShopClusterPoint,
  MultipleShopClusterPoint,
} from '@/types/map';

export const isShopClusterPoint = (
  cluster: ClusterPoint,
): cluster is ShopClusterPoint => {
  if ('cluster' in cluster.properties && cluster.properties.cluster) {
    return true;
  }

  return false;
};

export const isMultipleShopClusterPoint = (
  cluster: ClusterPoint,
): cluster is MultipleShopClusterPoint => {
  if ('shops' in cluster.properties && cluster.properties.shops) {
    return true;
  }

  return false;
};

export const isSingleShopClusterPoint = (
  cluster: ClusterPoint,
): cluster is SingleShopClusterPoint => {
  if ('shop' in cluster.properties && cluster.properties.shop) {
    return true;
  }

  return false;
};

export const getClusterPointId = (cluster: ClusterPoint): string => {
  if (isShopClusterPoint(cluster)) {
    return cluster.properties.cluster_id.toString();
  }

  if (isMultipleShopClusterPoint(cluster)) {
    return (cluster as MultipleShopClusterPoint).properties.shops[0].id;
  }

  if (isSingleShopClusterPoint(cluster)) {
    return (cluster as SingleShopClusterPoint).properties.shop.id;
  }

  return '';
};
