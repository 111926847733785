import axios, {
  Axios,
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';

export interface IBaseApiProvider {
  patch<R, T>(url: string, body: R, headers?: AxiosRequestConfig): Promise<T>;
  post<R, T>(url: string, body: R, headers?: AxiosRequestConfig): Promise<T>;
  delete<T>(url: string, headers?: AxiosRequestConfig): Promise<T>;
  get<T>(url: string, headers?: AxiosRequestConfig): Promise<T>;
}

export abstract class BaseApiProvider implements IBaseApiProvider {
  fetcher: Axios;

  constructor(protected readonly options?: AxiosRequestConfig) {
    this.fetcher = axios.create(options);
  }

  static hasAxiosError(response: AxiosError | unknown): response is AxiosError {
    return (response as AxiosError).isAxiosError !== undefined;
  }

  private handleResponse(response: AxiosResponse | AxiosError) {
    if ('isAxiosError' in response) {
      return response;
    }

    return response.data;
  }

  async patch<R, T>(
    url: string,
    body: R,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const response = await this.fetcher.patch(url, body, config);
    return this.handleResponse(response);
  }

  async post<R, T>(
    url: string,
    body: R,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const response = await this.fetcher.post(url, body, config);
    return this.handleResponse(response);
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.fetcher.get(url, config);
    return this.handleResponse(response);
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.fetcher.delete(url, config);
    return this.handleResponse(response);
  }
}
