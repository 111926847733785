import { API_URL } from '@/common/client/constants';

import { BaseApiProvider } from './Base.api';

export class ProductsApiProvider extends BaseApiProvider {
  static create() {
    return new ProductsApiProvider({ baseURL: `${API_URL}/products` });
  }

  createProduct = (product: IProductCreateRequest) =>
    this.post<IProductCreateRequest, IProductCreateResponse>('', product, {
      withCredentials: true,
    });

  updateProduct = (product: IProductUpdateRequest) =>
    this.patch<IProductUpdateRequest, IProductUpdateResponse>(
      `/${product.id}`,
      product,
      {
        withCredentials: true,
      },
    );

  deleteProduct = async (productId: string) =>
    this.delete<IProductDTO>(`/${productId}`, {
      withCredentials: true,
    });
}
