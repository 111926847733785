import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import {
  IResource,
  cleared,
  loading,
  loadedWithPayload,
  creatingWithPayload,
  failedWithError,
  ResourceStatus,
  isResourceCreating,
  isResourceReadyWithData,
  readyWithPayload,
  isResourceIdle,
  getResourceData,
  isResourceLoading,
  updatedWithPayload,
  updatingWithPayload,
  isResourceUpdating,
  createdWithPayload,
} from '@/utils/resource';
import type { ShopStatus } from '@/utils/constants';
import type { ShopFormData } from '@/components/mui/shop-edit-view';

import type { AppState, AppThunkExtra } from '../app';
import { signOut } from './user';
import { selectDraftProductData } from './draft-product';

export type IUserShopState = IResource<IShopDTO>;

const initialState: IUserShopState = {
  status: ResourceStatus.idle,
};

export const fetchUserShops = createAsyncThunk<
  IShopsWithProductsDTO,
  void,
  AppThunkExtra
>('userShop/getUserShops', async (_, { extra: { api } }) =>
  api.shops.getUserShopsWithProducts(),
);

export const createShop = createAsyncThunk<
  { userShop: IShopDTO; userProduct: IProductDTO | null },
  { name: string; address: string; coordinates: ICoordinates },
  AppThunkExtra
>(
  'userShop/createShop',
  async ({ name, address, coordinates }, { getState, extra: { api } }) => {
    const userShopData: IShopCreateRequest = {
      name,
      address,
      coordinates,
      imageUrls: [],
    };

    const userShop = await api.shops.createShop(userShopData);

    const userProductData = selectDraftProductData(getState());

    if (userProductData) {
      const images = await api.images.uploadRawProductImages(
        userProductData.imageUrls,
      );

      const userProduct = await api.products.createProduct({
        ...userProductData,
        imageUrls: images,
        shopId: userShop.id,
      });

      return {
        userShop,
        userProduct,
      };
    }

    return {
      userShop,
      userProduct: null,
    };
  },
);

export const updateShop = createAsyncThunk<
  IShopDTO,
  ShopFormData & { id: string },
  AppThunkExtra
>(
  'userShop/updateShop',
  async ({ imageUrls, address, ...data }, { extra: { api } }) => {
    const additionalFields: Record<string, any> = {};

    if (imageUrls) {
      additionalFields.imageUrls = await api.images.uploadRawShopImages(
        imageUrls,
      );
    }

    return api.shops.updateShop({
      ...data,
      ...additionalFields,
      coordinates: address.coordinates,
      address: address.formattedAddress,
    });
  },
);

export const toggleShopStatus = createAsyncThunk<
  IShopDTO,
  { id: string; status: ShopStatus },
  AppThunkExtra
>('userShop/toggleShopStatus', async (data, { extra: { api } }) =>
  api.shops.updateShop(data),
);

export const deleteShop = createAsyncThunk<IShopDTO, string, AppThunkExtra>(
  'userShop/deleteShop',
  async (shopId, { extra: { api } }) => api.shops.deleteShop(shopId),
);

const userShopSlice = createSlice({
  name: 'userShop',
  initialState,
  reducers: {
    preload: (state, { payload }: PayloadAction<IShopDTO>) =>
      readyWithPayload(state, { payload }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(signOut, cleared)

      .addCase(fetchUserShops.pending, loading)
      .addCase(fetchUserShops.fulfilled, (state, { payload }) =>
        loadedWithPayload(state, {
          payload: payload.shops[0],
        }),
      )
      .addCase(fetchUserShops.rejected, failedWithError)

      .addCase(createShop.pending, creatingWithPayload)
      .addCase(createShop.fulfilled, (state, { payload: { userShop } }) =>
        createdWithPayload(state, { payload: userShop }),
      )
      .addCase(createShop.rejected, failedWithError)

      .addCase(updateShop.pending, updatingWithPayload)
      .addCase(updateShop.fulfilled, updatedWithPayload)
      .addCase(updateShop.rejected, failedWithError)

      .addCase(toggleShopStatus.pending, updatingWithPayload)
      .addCase(toggleShopStatus.fulfilled, updatedWithPayload)
      .addCase(toggleShopStatus.rejected, failedWithError)

      .addCase(deleteShop.pending, creatingWithPayload)
      .addCase(deleteShop.fulfilled, cleared)
      .addCase(deleteShop.rejected, failedWithError)

      .addCase(HYDRATE, (state, { payload }: any) =>
        payload && 'userShop' in payload && isResourceIdle(state)
          ? payload.userShop
          : state,
      ),
});

export const {
  actions: { preload: preloadUserShop },
  reducer: userShopReducer,
} = userShopSlice;

// Selectors

export const selectUserShopState = (state: AppState) => state.userShop;

export const selectIsUserShopLoading = createSelector(
  selectUserShopState,
  isResourceLoading,
);

export const selectIsUserShopCreating = createSelector(
  selectUserShopState,
  isResourceCreating,
);

export const selectIsUserShopUpdating = createSelector(
  selectUserShopState,
  isResourceUpdating,
);

export const selectIsUserShopRegistered = createSelector(
  selectUserShopState,
  isResourceReadyWithData,
);

export const selectUserShop = createSelector(
  selectUserShopState,
  (userResource) => getResourceData(userResource),
);

export const selectHasUserShop = createSelector(
  selectUserShopState,
  (userResource) => Boolean(getResourceData(userResource)),
);

export const selectUserShopId = createSelector(
  selectUserShop,
  (userShop) => userShop?.id ?? undefined,
);
