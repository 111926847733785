import { createSelector } from '@reduxjs/toolkit';

import { selectProductIdQueryParam } from '../services';
import { selectUserProductsRecord } from './user-products';
import { selectProductRecords } from './products';

export const selectActiveProduct = createSelector(
  selectProductIdQueryParam,
  selectProductRecords,
  (activeProductId, records) =>
    activeProductId && records ? records[activeProductId] : undefined,
);

export const selectActiveUserProduct = createSelector(
  selectProductIdQueryParam,
  selectUserProductsRecord,
  (activeProductId, records) =>
    activeProductId && records ? records[activeProductId] : undefined,
);
