import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';

import { isBrowser } from '@/utils/is-browser';
import { isDevelopment } from '@/utils/is-development';

import { createAppApiService, createFirebaseAuthMiddleware } from '../services';
import { appReducer } from './app-reducer';
import { configureAppThunkMiddleware } from './app-thunk';

export const configureAppStore = () => {
  const appApi = createAppApiService();

  return configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => {
      const defaultMiddleware = getDefaultMiddleware({
        thunk: configureAppThunkMiddleware(appApi),
      });

      if (isDevelopment && isBrowser) {
        defaultMiddleware.push(
          createLogger({
            collapsed: true,
          }),
        );
      }

      return isBrowser
        ? defaultMiddleware.concat(createFirebaseAuthMiddleware(appApi))
        : defaultMiddleware;
    },
  });
};
