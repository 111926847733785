import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import {
  loading,
  IResource,
  ResourceStatus,
  isResourceIdle,
  failedWithError,
  getResourceData,
  loadedWithPayload,
  readyWithPayload,
} from '@/utils/resource';
import { transformToRecord } from '@/utils/transform-to-record';
import { isPresent } from '@/utils/is-present';

import type { AppState, AppThunkExtra } from '../app';

type CategoryId = ICategoryDTO['id'];

export type ICategoriesState = IResource<
  Partial<Record<CategoryId, ICategoryDTO>>
>;

const initialState: ICategoriesState = {
  status: ResourceStatus.idle,
};

export const fetchCategories = createAsyncThunk<
  Record<string, ICategoryDTO>,
  any,
  AppThunkExtra
>('categories/getAll', (_, { extra: { api } }) =>
  api.categories.getCategories().then(transformToRecord),
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    preload: (state, { payload }: PayloadAction<Array<ICategoryDTO>>) =>
      readyWithPayload(state, { payload: transformToRecord(payload) }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(HYDRATE, (state, { payload }: any) =>
        payload && 'categories' in payload && isResourceIdle(state)
          ? payload.categories
          : state,
      )

      .addCase(fetchCategories.pending, loading)
      .addCase(fetchCategories.fulfilled, loadedWithPayload)
      .addCase(fetchCategories.rejected, failedWithError),
});

export const {
  actions: { preload: preloadCategories },
  reducer: categoriesReducer,
} = categoriesSlice;

// Selectors

export const selectCategoriesState = (state: AppState) => state.categories;

export const selectCategoriesRecords = createSelector(
  selectCategoriesState,
  (resource) => getResourceData(resource),
);

export const selectCategoriesList = createSelector(
  selectCategoriesRecords,
  (categories) =>
    categories ? Object.values(categories).filter(isPresent) : undefined,
);

export const selectCategory = createSelector(
  selectCategoriesRecords,
  (categories) => (id: string) => categories?.[id],
);

export const selectCategoryByName = createSelector(
  selectCategoriesList,
  (categories) => (categoryName: string) =>
    categories?.find((category) => category?.name === categoryName),
);
