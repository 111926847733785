import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { WritableDraft } from 'immer/dist/types/types-external';

import type { ISnackbarProps } from '@/components/mui/secondary-snackbar';
import { ProductStatus, ShopStatus } from '@/utils/constants';

import type { AppState } from '../app';
import {
  createShop,
  deleteShop,
  toggleShopStatus,
  updateShop,
} from './user-shop';
import {
  createProduct,
  deleteProduct,
  toggleProductStatus,
  updateProduct,
} from './user-products';

export interface INotificationData extends ISnackbarProps {
  key?: string;
}

export interface INotificationsState {
  stack: Array<INotificationData>;
}

const initialState: INotificationsState = {
  stack: [],
};

const sidebarOrigin: ISnackbarProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 'right',
};

const addSidebarMessage =
  (message: string) => (state: WritableDraft<INotificationsState>) => {
    state.stack.push({
      anchorOrigin: sidebarOrigin,
      type: 'sidebar',
      message,
    });
  };

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<INotificationData>) => {
      const snack: INotificationData = {
        ...action.payload,
        key: action.payload.key || Date.now().toString(),
      };

      // @ts-expect-error bad typings
      state.stack = [...state.stack, snack];
    },
    hideNotification: (state) => {
      state.stack = state.stack.slice(1);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createProduct.fulfilled, addSidebarMessage('Товар додано'))
      .addCase(
        createProduct.rejected,
        addSidebarMessage('Помилка додавання товару'),
      )

      .addCase(updateProduct.fulfilled, addSidebarMessage('Товар оновлено'))
      .addCase(
        updateProduct.rejected,
        addSidebarMessage('Помилка оновлення товару'),
      )

      .addCase(toggleProductStatus.fulfilled, (state, { payload }) => {
        if (payload.status === ProductStatus.Active) {
          addSidebarMessage('Товар активований та відображається у лавці!')(
            state,
          );
        } else {
          addSidebarMessage('Товар деактивований та схований з лавки')(state);
        }
      })
      .addCase(toggleProductStatus.rejected, (state, { payload }) => {
        if ((payload as IProductDTO).status === ProductStatus.Active) {
          addSidebarMessage('Помилка деактивації товару')(state);
        } else {
          addSidebarMessage('Помилка активації товару')(state);
        }
      })

      .addCase(deleteProduct.fulfilled, addSidebarMessage('Товар видалено'))
      .addCase(
        deleteProduct.rejected,
        addSidebarMessage('Помилка видалення товару'),
      )

      .addCase(createShop.fulfilled, addSidebarMessage('Лавку додано'))
      .addCase(
        createShop.rejected,
        addSidebarMessage('Помилка додавання лавки'),
      )

      .addCase(updateShop.fulfilled, addSidebarMessage('Лавку оновлено'))
      .addCase(
        updateShop.rejected,
        addSidebarMessage('Помилка оновлення лавки'),
      )

      .addCase(toggleShopStatus.fulfilled, (state, { payload }) => {
        if (payload.status === ShopStatus.Active) {
          addSidebarMessage(
            'Лавка успішно активована та відображається на мапі!',
          )(state);
        } else {
          addSidebarMessage('Лавка деактивована та схована з мапи')(state);
        }
      })
      .addCase(
        toggleShopStatus.rejected,
        addSidebarMessage('Помилка активації лавки'),
      )

      .addCase(deleteShop.fulfilled, addSidebarMessage('Лавку видалено'))
      .addCase(
        deleteShop.rejected,
        addSidebarMessage('Помилка видалення лавки'),
      ),
});

export const {
  actions: { addNotification, hideNotification },
  reducer: notificationsReducer,
} = notificationsSlice;

// Selectors

export const selectNotificationsState = (state: AppState) =>
  state.notifications;

export const selectNotificationsStack = createSelector(
  selectNotificationsState,
  (notifications) => notifications.stack,
);
