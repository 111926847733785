import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import {
  IResource,
  ResourceStatus,
  getResourceData,
  isResourceLoading,
  loading,
  loaded,
  isResourceIdle,
  failedWithError,
  readyWithPayload,
  hydrate,
} from '@/utils/resource';
import { transformToRecord } from '@/utils/transform-to-record';
import { isPresent } from '@/utils/is-present';

import type { AppState } from '../app';
import { selectCategoryIdQueryParam } from '../services';
import { selectCategory } from './categories';
import { fetchShopsAndProductsByLocation } from './shops';

type ProductId = IProductDTO['id'];

export type IProductsState = IResource<Partial<Record<ProductId, IProductDTO>>>;

const initialState: IProductsState = {
  status: ResourceStatus.idle,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    preload: (state, { payload }: PayloadAction<IProductDTO[]>) =>
      readyWithPayload(state, { payload: transformToRecord(payload) }),
    preloadActiveProduct: (
      state,
      { payload: activeProduct }: PayloadAction<IProductDTO>,
    ) =>
      hydrate(state, {
        ...getResourceData(state),
        [activeProduct.id]: activeProduct,
      }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(HYDRATE, (state, { payload }: any) =>
        payload && 'products' in payload && isResourceIdle(state)
          ? payload.products
          : state,
      )

      .addCase(fetchShopsAndProductsByLocation.pending, loading)
      .addCase(fetchShopsAndProductsByLocation.rejected, failedWithError)
      .addCase(
        fetchShopsAndProductsByLocation.fulfilled,
        (state, { payload: { activeProductId, products } }) => {
          const activeProductData = activeProductId
            ? { [activeProductId]: getResourceData(state)?.[activeProductId] }
            : {};

          return loaded(state, {
            ...activeProductData,
            ...transformToRecord(products),
          });
        },
      ),
});

export const {
  actions: { preload: preloadProducts, preloadActiveProduct },
  reducer: productsReducer,
} = productsSlice;

// Selectors

export const selectProductsState = (state: AppState): IProductsState =>
  state.products;

export const selectIsProductsResourceIdle = createSelector(
  selectProductsState,
  isResourceIdle,
);

export const selectIsProductsResourceLoading = createSelector(
  selectProductsState,
  isResourceLoading,
);

export const selectProductRecords = createSelector(
  selectProductsState,
  (records) => getResourceData(records),
);

export const selectProductsList = createSelector(
  selectProductRecords,
  (shopRecords) =>
    shopRecords ? Object.values(shopRecords).filter(isPresent) : undefined,
);

export const selectProductsListCount = createSelector(
  selectProductsList,
  (productsList) => productsList?.length,
);

export const selectProductsCategoryIds = createSelector(
  selectProductsList,
  (products) =>
    products
      ? new Set(products.map((product) => product.categoryId))
      : undefined,
);

export const selectProductsCategories = createSelector(
  selectProductsCategoryIds,
  selectCategory,
  (categoryIds, getCategory) =>
    categoryIds
      ? Array.from(categoryIds).map(getCategory).filter(isPresent)
      : undefined,
);

export const selectProductsListByCategories = createSelector(
  selectProductsList,
  selectCategoryIdQueryParam,
  (products, categories) =>
    categories?.length
      ? products?.filter(({ categoryId }) => categories.includes(categoryId))
      : products,
);
