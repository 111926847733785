import { API_URL } from '@/common/client/constants';

import { BaseApiProvider } from './Base.api';

export class ShopsApiProvider extends BaseApiProvider {
  static create() {
    return new ShopsApiProvider({ baseURL: `${API_URL}/shops` });
  }

  getUserShopsWithProducts = async () => {
    return this.get<IShopsWithProductsDTO>('', {
      withCredentials: true,
    });
  };

  createShop = async (shop: IShopCreateRequest) => {
    return this.post<IShopCreateRequest, IShopCreateResponse>('', shop, {
      withCredentials: true,
    });
  };

  updateShop = async (shop: IShopUpdateRequest) => {
    return this.patch<IShopUpdateRequest, IShopUpdateResponse>(
      `/${shop.id}`,
      shop,
      {
        withCredentials: true,
      },
    );
  };

  deleteShop = async (shopId: string) => {
    return this.delete<IShopDTO>(`/${shopId}`, {
      withCredentials: true,
    });
  };

  getShopsAndProductsByLocation = async (
    location: IGetShopProductsLocationRequest,
  ) =>
    this.get<IGetShopsLocationResponse>('/clustered', {
      params: {
        zoom: location.zoom,
        bounds: location.bounds
          ?.map((bound) => bound.toPrecision(6))
          ?.toString(),
      },
    });
}
