import React from 'react';
import type { AppProps } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Analytics } from '@vercel/analytics/react';

import type { NextPageWithContainer } from '@/types/page';
import { AppHead } from '@/components/mui/app-head';
import { AppNotifications } from '@/containers/app-notifications';
import { AppHeightProvider } from '@/contexts/app-height';
import { useFullStory } from '@/hooks/use-full-story';
import { useStoreQueryParams } from '@/hooks/use-store-query-params';
import createEmotionCache from '@/utils/create-emotion-cache';
import { eBazarTheme } from '@/theme/e-bazar-theme';
import { nextWrapper } from '@/store/app';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface IEBazarAppProps extends AppProps {
  Component: NextPageWithContainer;
  emotionCache?: EmotionCache;
}

function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: IEBazarAppProps) {
  useFullStory();
  useStoreQueryParams();
  const withContainer = Component.withContainer ?? ((component) => component);

  return (
    <CacheProvider value={emotionCache}>
      <AppHead />
      <ThemeProvider theme={eBazarTheme}>
        <CssBaseline />
        <AppHeightProvider>
          {withContainer(<Component {...pageProps} />)}
          <AppNotifications />
        </AppHeightProvider>
      </ThemeProvider>
      <Analytics />
    </CacheProvider>
  );
}

export default nextWrapper.withRedux(App);
