import type { ParsedUrlQuery } from 'querystring';

export const validateShopQueryParams = (
  query: ParsedUrlQuery,
): Partial<IShopQueryParams> => {
  const { shopId, shopTab } = query;
  const shopQueryParams: Partial<IShopQueryParams> = {};

  if (typeof shopId === 'string') {
    shopQueryParams.shopId = shopId;
  }

  if (
    shopTab === 'products' ||
    shopTab === 'contacts' ||
    shopTab === 'edit-shop'
  ) {
    shopQueryParams.shopTab = shopTab;
  }

  return shopQueryParams;
};

export const validateProductQueryParams = (
  query: ParsedUrlQuery,
): Partial<IProductQueryParams> => {
  const { productId, productTab } = query;
  const productQueryParams: Partial<IProductQueryParams> = {};

  if (typeof productId === 'string') {
    productQueryParams.productId = productId;
  }

  if (
    productTab === 'product' ||
    productTab === 'add-product' ||
    productTab === 'edit-product'
  ) {
    productQueryParams.productTab = productTab;
  }

  return productQueryParams;
};

export const validateCategoryQueryParams = (
  query: ParsedUrlQuery,
): Partial<ICategoryQueryParams> => {
  const { categoryId } = query;
  const categoryQueryParams: Partial<ICategoryQueryParams> = {};

  if (typeof categoryId === 'string') {
    categoryQueryParams.categoryId = [categoryId];
  }

  if (Array.isArray(categoryId)) {
    categoryQueryParams.categoryId = categoryId.filter(
      (category) => typeof category === 'string',
    );
  }

  return categoryQueryParams;
};

export const validateLocationQueryParams = (
  query: ParsedUrlQuery,
): Partial<ILocationQueryParams> => {
  const mapQueryParams: Partial<ILocationQueryParams> = {};

  const [lat, lng, r] = [query.lat, query.lng, query.r].map((param) =>
    parseFloat(!Array.isArray(param) ? param ?? '' : ''),
  );

  if (Number.isFinite(lat) && Number.isFinite(lng) && Number.isFinite(r)) {
    mapQueryParams.lat = lat;
    mapQueryParams.lng = lng;
    mapQueryParams.r = r;
  }

  return mapQueryParams;
};

const validators = [
  validateShopQueryParams,
  validateProductQueryParams,
  validateCategoryQueryParams,
  validateLocationQueryParams,
];

export const validateAppQueryParams = (
  query: ParsedUrlQuery,
): IAppQueryParams =>
  validators.reduce(
    (queryParams, validator) => ({ ...queryParams, ...validator(query) }),
    {},
  );
