import * as firebase from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { Middleware, miniSerializeError } from '@reduxjs/toolkit';
import { noop } from 'lodash';

import { LANGUAGE_CODE } from '@/utils/constants';
import { sanitizePhoneNumber } from '@/utils/sanitize-phone-number';

import {
  signIn,
  signOut,
  authFailed,
  createProduct,
  fetchUserShops,
  selectUserPhoneNumber,
  selectDraftProductData,
} from '../../domains';
import type { AppDispatch } from '../../app';
import type { AppApi } from '../app-api';
import { getFirebaseConfigFromRuntime } from './config';

export const createFirebaseAuthMiddleware = (api: AppApi): Middleware => {
  firebase.initializeApp(getFirebaseConfigFromRuntime());
  getAuth().languageCode = LANGUAGE_CODE;

  return ({ dispatch, getState }) => {
    getAuth().onAuthStateChanged(
      async (firebaseUser) => {
        if (firebaseUser) {
          try {
            const userPhone = selectUserPhoneNumber(getState());
            const firebaseUserPhone = sanitizePhoneNumber(
              firebaseUser.phoneNumber || '',
            );

            if (userPhone === firebaseUserPhone) {
              return;
            }

            const firebaseToken = await firebaseUser.getIdToken();
            const { user } = await api.auth.signInWithFirebase(firebaseToken);

            dispatch(signIn(user));

            (dispatch as AppDispatch)(fetchUserShops()).then(() => {
              /**
               * If user created a shop when was not logged in,
               * we need to create a new product from session storage
               */
              const newUserProduct = selectDraftProductData(getState());

              if (newUserProduct) {
                (dispatch as AppDispatch)(createProduct(newUserProduct));
              }
            });
          } catch (error) {
            if (error instanceof Error) {
              dispatch(authFailed(miniSerializeError(error)));
            }
          }
        } else {
          /**
           * We don't sign out user when firebase authentication expired
           */
        }
      },
      (error) => dispatch(authFailed(miniSerializeError(error))),
    );

    return (next) => (action) => {
      if (typeof action === 'object' && action.type === signOut.type) {
        api.auth.signOut().catch(noop);
        getAuth().signOut().catch(noop);
      }

      return next(action);
    };
  };
};
