import type { SerializedError } from '@reduxjs/toolkit';

import { isPresent } from './is-present';

export enum ResourceStatus {
  idle = 'idle',
  ready = 'ready',
  failed = 'failed',
  loading = 'loading',
  creating = 'creating',
  updating = 'updating',
  deleting = 'deleting',
}

export interface IResource<D> {
  data?: D;
  error?: SerializedError;
  status: ResourceStatus;
  pending?: D;
}

// Resource modifiers

export const ready = <D>(resource: IResource<D>, data: D) => {
  resource.status = ResourceStatus.ready;
  resource.data = data;
  return resource;
};

export const failed = <D>(resource: IResource<D>, error: SerializedError) => {
  resource.status = ResourceStatus.failed;
  resource.error = error;
  return resource;
};

export const cleared = <D>(resource: IResource<D>) => {
  resource.status = ResourceStatus.ready;
  delete resource.data;
  delete resource.error;
  delete resource.pending;
  return resource;
};

export const loading = <D>(resource: IResource<D>) => {
  resource.status = ResourceStatus.loading;
  return resource;
};

export const loaded = <D>(resource: IResource<D>, data: D) => {
  delete resource.error;
  return ready(resource, data);
};

export const creating = <D>(resource: IResource<D>, data: D) => {
  resource.status = ResourceStatus.creating;
  resource.pending = data;
  return resource;
};

export const created = <D>(resource: IResource<D>, data: D) => {
  delete resource.error;
  delete resource.pending;
  return ready(resource, data);
};

export const updating = <D>(resource: IResource<D>, data: D) => {
  resource.status = ResourceStatus.updating;
  resource.pending = data;
  return resource;
};

export const updated = <D>(resource: IResource<D>, data: D) => {
  delete resource.error;
  delete resource.pending;
  return ready(resource, data);
};

export const deleting = <D>(resource: IResource<D>) => {
  resource.status = ResourceStatus.deleting;
  resource.pending = resource.data;
  delete resource.data;
  return resource;
};

export const deleted = cleared;

export const hydrate = <D>(resource: IResource<D>, data?: D) => {
  if (data) resource.data = data;
  return resource;
};

// Resource getters

export const getResourceStatus = <D>(resource?: IResource<D>) =>
  resource?.status;

export const getResourceData = <D>(resource?: IResource<D>): D | undefined =>
  resource?.data;

export const getResourcePendingData = <D>(
  resource?: IResource<D>,
): D | undefined => resource?.pending;

export const getResourceError = <D>(
  resource?: IResource<D>,
): SerializedError | undefined => resource?.error;

export const getResourceOptimisticData = <D>(
  resource?: IResource<D>,
): D | undefined =>
  getResourcePendingData(resource) ?? getResourceData(resource);

// Resource status predicates

const createResourceStatusPredicate =
  (status: ResourceStatus) =>
  <D>(resource?: IResource<D>) =>
    resource?.status === status;

export const [
  isResourceIdle,
  isResourceReady,
  isResourceFailed,
  isResourceLoading,
  isResourceCreating,
  isResourceUpdating,
  isResourceDeleting,
] = [
  ResourceStatus.idle,
  ResourceStatus.ready,
  ResourceStatus.failed,
  ResourceStatus.loading,
  ResourceStatus.creating,
  ResourceStatus.updating,
  ResourceStatus.deleting,
].map(createResourceStatusPredicate);

export const isResourceReadyWithData = <D>(resource?: IResource<D>) =>
  isResourceReady(resource) && isPresent(getResourceData(resource));

// Special resource modifiers

const createModifierWithPayload =
  (modifier: <D>(resource: IResource<D>, data: D) => IResource<D>) =>
  <D, T extends { payload?: D }>(resource: IResource<D>, { payload }: T) =>
    modifier(resource, payload);

export const [
  readyWithPayload,
  loadedWithPayload,
  creatingWithPayload,
  createdWithPayload,
  updatingWithPayload,
  updatedWithPayload,
] = [ready, loaded, creating, created, updating, updated].map(
  createModifierWithPayload,
);

export const failedWithError = <D, E extends { error: SerializedError }>(
  resource: IResource<D>,
  { error }: E,
) => failed(resource, error);
