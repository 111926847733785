import { AuthApiProvider } from '@/api/Auth.api';
import { ShopsApiProvider } from '@/api/Shops.api';
import { ImagesApiProvider } from '@/api/Images.api';
import { ProductsApiProvider } from '@/api/Products.api';
import { CategoriesApiProvider } from '@/api/Categories.api';

export const createAppApiService = () => ({
  auth: AuthApiProvider.create(),
  shops: ShopsApiProvider.create(),
  images: ImagesApiProvider.create(),
  products: ProductsApiProvider.create(),
  categories: CategoriesApiProvider.create(),
});
