import React from 'react';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

export interface ISnackbarProps extends SnackbarProps {
  type?: 'sidebar';
}

export interface ISecondarySnackbarProps extends ISnackbarProps {
  onClose?: () => void;
  onExited?: () => void;
}

const StyledSnackbar = styled(Snackbar)<ISnackbarProps>(({ theme, type }) => ({
  ...(type === 'sidebar' && {
    [theme.breakpoints.up('xs')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
  ['> div:first-of-type']: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.secondary.main,
    ...(type === 'sidebar' && {
      width: 328,
      ['> div:first-of-type']: {
        flex: '0 0 241px',
      },
    }),
  },
}));

export function SecondarySnackbar({
  onClose,
  onExited,
  autoHideDuration = 6000,
  ...props
}: ISecondarySnackbarProps) {
  const onSnackbarClose = (
    _: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') return;
    onClose?.();
  };

  return (
    <StyledSnackbar
      {...props}
      onClose={onSnackbarClose}
      autoHideDuration={autoHideDuration}
      TransitionProps={{ onExited, ...props.TransitionProps }}
      action={
        <IconButton size="small" color="inherit" onClick={onSnackbarClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}
