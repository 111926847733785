import { useCallback, useEffect, useState } from 'react';

import { SecondarySnackbar } from '@/components/mui/secondary-snackbar';
import { useAppDispatch, useAppSelector } from '@/store/app';
import {
  hideNotification,
  INotificationData,
  selectNotificationsStack,
} from '@/store/domains';
import { useOpenState } from '@/hooks/use-open-state';

export function AppNotifications() {
  const dispatch = useAppDispatch();

  const notifications = useAppSelector(selectNotificationsStack);
  const [currentNotification, setCurrentNotification] =
    useState<INotificationData>();

  const onExited = useCallback(() => {
    setCurrentNotification(undefined);
  }, []);

  const { open, onOpen, onClose } = useOpenState(false);

  useEffect(() => {
    if (notifications.length && !currentNotification) {
      // Set a new snack when we don't have an active one
      setCurrentNotification(notifications[0]);
      dispatch(hideNotification());
      onOpen();
    } else if (notifications.length && currentNotification && open) {
      // Close an active snack when a new one is added
      onClose();
    }
  }, [open, onClose, onOpen, currentNotification, notifications, dispatch]);

  return (
    <SecondarySnackbar
      {...currentNotification}
      open={open}
      onClose={onClose}
      onExited={onExited}
    />
  );
}
