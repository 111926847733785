import { HYDRATE } from 'next-redux-wrapper';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  loaded,
  loading,
  IResource,
  failedWithError,
  isResourceIdle,
  ResourceStatus,
  getResourceData,
  readyWithPayload,
} from '@/utils/resource';
import type { ClusterPoint, SingleShopClusterPoint } from '@/types/map';
import { isSingleShopClusterPoint } from '@/utils/clusters';

import type { AppState } from '../app';
import { fetchShopsAndProductsByLocation } from './shops';
import { selectUserShop } from './user-shop';

export type IClustersState = IResource<Array<ClusterPoint>>;

const initialState: IClustersState = {
  status: ResourceStatus.idle,
};

const clustersSlice = createSlice({
  name: 'clusters',
  initialState,
  reducers: {
    preload: (state, { payload }: PayloadAction<Array<ClusterPoint>>) =>
      readyWithPayload(state, { payload }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(HYDRATE, (state, { payload }: any) =>
        payload && 'clusters' in payload && isResourceIdle(state)
          ? payload.clusters
          : state,
      )

      .addCase(fetchShopsAndProductsByLocation.pending, loading)
      .addCase(fetchShopsAndProductsByLocation.rejected, failedWithError)
      .addCase(
        fetchShopsAndProductsByLocation.fulfilled,
        (state, { payload: { clusters } }) => {
          return loaded(state, clusters);
        },
      ),
});

export const {
  reducer: clustersReducer,
  actions: { preload: preloadClusters },
} = clustersSlice;

// Selectors

export const selectClustersState = (state: AppState): IClustersState =>
  state.clusters;

export const selectClusters = createSelector(selectClustersState, (resource) =>
  getResourceData(resource),
);

export const selectClustersList = createSelector(
  selectClusters,
  selectUserShop,
  (clusters, userShop) => {
    if (userShop && clusters) {
      const userShopPoint: SingleShopClusterPoint = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [userShop.coordinates.lng, userShop.coordinates.lat],
        },
        properties: {
          shop: {
            id: userShop.id,
            name: userShop.name,
          },
        },
      };

      return clusters
        .filter((clusterPoint) =>
          isSingleShopClusterPoint(clusterPoint)
            ? clusterPoint.properties.shop.id !== userShop.id
            : true,
        )
        .concat(userShopPoint);
    }

    return clusters;
  },
);
