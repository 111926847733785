import type { AnyAction, Dispatch, ThunkAction } from '@reduxjs/toolkit';

import type { AppApi } from '../services';
import type { AppState } from './app-state';

export const configureAppThunkMiddleware = (api: AppApi) => ({
  extraArgument: {
    api,
  },
});

export type AppExtraThunkArg = ReturnType<
  typeof configureAppThunkMiddleware
>['extraArgument'];

export type AppThunkExtra = {
  extra: AppExtraThunkArg;
  dispatch: Dispatch;
  state: AppState;
};

export type AppThunk<Returned = void> = ThunkAction<
  Returned,
  AppState,
  AppExtraThunkArg,
  AnyAction
>;
