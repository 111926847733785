import { combineReducers } from '@reduxjs/toolkit';

import {
  mapReducer,
  shopsReducer,
  productsReducer,
  userReducer,
  userShopReducer,
  categoriesReducer,
  userProductsReducer,
  draftProductReducer,
  notificationsReducer,
  clustersReducer,
} from '../domains';
import { queryParamsReducer } from '../services';

export const appReducer = combineReducers({
  user: userReducer,
  userShop: userShopReducer,
  userProducts: userProductsReducer,
  map: mapReducer,
  shops: shopsReducer,
  products: productsReducer,
  queryParams: queryParamsReducer,
  categories: categoriesReducer,
  clusters: clustersReducer,
  draftProduct: draftProductReducer,
  notifications: notificationsReducer,
});
