import { createTheme } from '@mui/material/styles';

const eUkraineFont = '/fonts/eUkraine/a44207bfe4040f536c824dbbf82f5ed1';

const eUkraineFontFamily = 'e-Ukraine Regular';

const eUkraineFontStyleOverride = `
  @font-face {
    font-family: "${eUkraineFontFamily}";
    src: url("${eUkraineFont}.eot"); /* IE9*/
    src: url("${eUkraineFont}.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("${eUkraineFont}.woff2") format("woff2"), /* chrome、firefox */
    url("${eUkraineFont}.woff") format("woff"), /* chrome、firefox */
    url("${eUkraineFont}.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("${eUkraineFont}.svg#e-Ukraine Regular") format("svg"); /* iOS 4.1- */
  }
`;

export const eBazarTheme = createTheme({
  palette: {
    primary: {
      main: '#5600E8',
    },
    secondary: {
      main: '#000000',
    },
    error: {
      main: '#F44336',
    },
    text: {
      primary: '#000000',
      secondary: '#698294',
    },
    background: {
      default: '#e4ecf3',
    },
    warning: {
      main: '#F50057',
    },
    info: {
      main: '#CFDEEB',
    },
  },
  typography: {
    fontFamily: `"${eUkraineFontFamily}","Roboto","Helvetica","Arial",sans-serif`,
    h6: {
      fontSize: 22,
      lineHeight: 1.27,
    },
    caption: {
      lineHeight: '18px',
      letterSpacing: '0.4px',
    },
    button: {
      fontWeight: 600,
      letterSpacing: '0.4px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: eUkraineFontStyleOverride,
    },
  },
});

eBazarTheme.typography.h5 = {
  ...eBazarTheme.typography.h5,
  [eBazarTheme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
};
