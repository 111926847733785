import {
  createSlice,
  PayloadAction,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import { HYDRATE } from 'next-redux-wrapper';

import {
  IResource,
  ResourceStatus,
  getResourceData,
  loading,
  isResourceIdle,
  failedWithError,
  isResourceUpdating,
  updatingWithPayload,
  updated,
  creatingWithPayload,
  created,
  isResourceCreating,
  loadedWithPayload,
  readyWithPayload,
} from '@/utils/resource';
import { transformToRecord } from '@/utils/transform-to-record';
import { isPresent } from '@/utils/is-present';
import type { ProductStatus } from '@/utils/constants';

import { createShop, fetchUserShops, selectUserShopId } from './user-shop';
import type { AppState, AppThunkExtra } from '../app';

type ProductId = IProductDTO['id'];

export type CreateProductData = Omit<
  IProductDTO,
  'id' | 'shopId' | 'imageUrls'
> & {
  imageUrls: Array<ICreateImageDTO>;
};

export type UpdateProductData = Omit<
  IProductUpdateRequest,
  'shopId' | 'categoryId' | 'imageUrls'
> & {
  imageUrls: Array<ICreateImageDTO>;
};

export type IUserProductsState = IResource<
  Partial<Record<ProductId, IProductDTO>>
>;

const initialState: IUserProductsState = {
  status: ResourceStatus.idle,
};

export const createProduct = createAsyncThunk<
  IProductCreateResponse,
  CreateProductData,
  AppThunkExtra
>(
  'userProducts/createProduct',
  async ({ imageUrls, ...data }, { getState, extra: { api } }) => {
    const shopId = selectUserShopId(getState());

    if (!shopId) {
      throw new Error('Shop not found');
    }

    const images = await api.images.uploadRawProductImages(imageUrls);

    const product = await api.products.createProduct({
      ...data,
      shopId,
      imageUrls: images,
    });

    return product;
  },
);

export const updateProduct = createAsyncThunk<
  IProductUpdateResponse,
  UpdateProductData,
  AppThunkExtra
>(
  'userProducts/updateProduct',
  async ({ imageUrls, ...productData }, { extra: { api } }) => {
    const additionalFields: { imageUrls?: Array<string> } = {};

    if (imageUrls) {
      additionalFields.imageUrls = await api.images.uploadRawProductImages(
        imageUrls,
      );
    }

    return api.products.updateProduct({
      ...productData,
      ...additionalFields,
    });
  },
);

export const toggleProductStatus = createAsyncThunk<
  IProductUpdateResponse,
  { id: string; status: ProductStatus },
  AppThunkExtra
>('userProducts/toggleProductStatus', (data, { extra: { api } }) =>
  api.products.updateProduct(data),
);

export const deleteProduct = createAsyncThunk<
  IProductDTO,
  string,
  AppThunkExtra
>('userProducts/deleteProduct', (productId, { extra: { api } }) => {
  return api.products.deleteProduct(productId);
});

const userProductsSlice = createSlice({
  name: 'userProducts',
  initialState,
  reducers: {
    preload: (state, { payload }: PayloadAction<IProductDTO[]>) =>
      readyWithPayload(state, { payload: transformToRecord(payload) }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(HYDRATE, (state, { payload }: any) =>
        payload && 'userProducts' in payload && isResourceIdle(state)
          ? payload.userProducts
          : state,
      )

      .addCase(createProduct.pending, creatingWithPayload)
      .addCase(createProduct.fulfilled, (state, { payload }) =>
        created(state, {
          ...getResourceData(state),
          [payload.id]: payload,
        }),
      )
      .addCase(createProduct.rejected, failedWithError)

      .addCase(updateProduct.pending, updatingWithPayload)
      .addCase(updateProduct.fulfilled, (state, { payload }) =>
        updated(state, {
          ...getResourceData(state),
          [payload.id]: payload,
        }),
      )
      .addCase(updateProduct.rejected, failedWithError)

      .addCase(toggleProductStatus.pending, updatingWithPayload)
      .addCase(toggleProductStatus.fulfilled, (state, { payload }) =>
        updated(state, {
          ...getResourceData(state),
          [payload.id]: payload,
        }),
      )
      .addCase(toggleProductStatus.rejected, failedWithError)

      .addCase(deleteProduct.pending, updatingWithPayload)
      .addCase(deleteProduct.fulfilled, (state, { payload }) =>
        updated(state, omit(getResourceData(state), [payload.id])),
      )
      .addCase(deleteProduct.rejected, failedWithError)

      .addCase(createShop.pending, creatingWithPayload)
      .addCase(createShop.fulfilled, (state, { payload: { userProduct } }) => {
        if (!userProduct) {
          return created(state, getResourceData(state));
        }

        return created(state, {
          ...getResourceData(state),
          [userProduct.id]: userProduct,
        });
      })
      .addCase(createShop.rejected, failedWithError)

      .addCase(fetchUserShops.pending, loading)
      .addCase(fetchUserShops.fulfilled, (state, { payload }) =>
        loadedWithPayload(state, {
          payload: transformToRecord(payload.products),
        }),
      )
      .addCase(fetchUserShops.rejected, failedWithError),
});

export const {
  actions: { preload: preloadUserProducts },
  reducer: userProductsReducer,
} = userProductsSlice;

// Selectors

export const selectUserProductsState = (state: AppState): IUserProductsState =>
  state.userProducts;

export const selectIsUserProductsResourceUpdating = createSelector(
  selectUserProductsState,
  isResourceUpdating,
);

export const selectIsUserProductsResourceCreating = createSelector(
  selectUserProductsState,
  isResourceCreating,
);

export const selectUserProductsRecord = createSelector(
  selectUserProductsState,
  (records) => getResourceData(records),
);

export const selectUserProductsList = createSelector(
  selectUserProductsRecord,
  (userProductsRecord) =>
    userProductsRecord
      ? Object.values(userProductsRecord).filter(isPresent)
      : undefined,
);
